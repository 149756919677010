import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Slider, NamedLink, IconPlay } from '../../components';

import css from './ListingPage.module.css';

// slidesToShow
const SLIDES_TO_SHOW_TABLET = 3;
const SLIDES_TO_SHOW_DESKTOP = 4.2;
// slidesToScroll
const SLIDES_TO_SCROLL_TABLET = 3;
const SLIDES_TO_SCROLL_DESKTOP = 2.6;

const videos = [
  { key: 'video1', label: 'Video #1' },
  { key: 'video2', label: 'Video #2' },
  { key: 'video3', label: 'Video #3' },
  { key: 'video4', label: 'Video #4' },
  { key: 'video5', label: 'Video #5' },
  { key: 'video6', label: 'Video #6' },
];

const VideoIcon = props => {
  return (
    <svg
      className={css.addVideoIcon}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="-1.145 -1.145 32 32"
      {...props}
    >
      <g stroke="#333" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.3">
        <path d="M26.527 26.527a2.122 2.122 0 0 1-2.122 2.122h-19.1a2.122 2.122 0 0 1-2.122-2.122V3.183a2.122 2.122 0 0 1 2.122-2.122H19.1l7.428 7.428v18.038Z" />
        <path d="M19.1 13.794 14.854 9.55l-4.244 4.244M14.855 9.55V21.22" />
      </g>
    </svg>
  );
};

const SectionAddVideos = props => {
  const { intl, editParams } = props;

  const sliderTitle = intl.formatMessage({ id: 'ListingPage.addVideosTitle' });

  return (
    <div className={css.sectionAddVideos}>
      <div className={css.highlights}>
        <Slider
          title={sliderTitle}
          headerClassName={css.sliderHeader}
          titleClassName={css.highlightsTitle}
          itemsClassName={css.highlightsSliderItems}
          itemClassName={css.highlightsSliderItem}
          arrowsClassName={css.highlightsSliderArrows}
          // Responsive slider settings
          slidesToShow={{
            tablet: SLIDES_TO_SHOW_TABLET,
            desktop: SLIDES_TO_SHOW_DESKTOP,
          }}
          slidesToScroll={{
            tablet: SLIDES_TO_SCROLL_TABLET,
            desktop: SLIDES_TO_SCROLL_DESKTOP,
          }}
        >
          {videos.map(video => {
            return (
              <div key={video.key} className={css.addVideo}>
                <div className={css.addVideoContent}>
                  <VideoIcon className={css.addVideoIcon} />
                  <span>{video.label}</span>
                  <NamedLink
                    className={css.addVideoButton}
                    name="EditListingPage"
                    params={{ ...editParams, tab: 'content' }}
                    to={{
                      search: 'activeTab=videos',
                    }}
                  >
                    <FormattedMessage id="ListingPage.addShortClip" />
                  </NamedLink>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default SectionAddVideos;
