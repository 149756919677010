import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Reviews,
  Review,
  InlineTextButton,
  NamedLink,
  ShowReviewModal,
  IconArrowHead,
} from '../../components';
import { THUMB_TYPE_DISLIKE, THUMB_TYPE_LIKE } from '../../util/types';
import { notification } from '../../util/notification';
import { createResourceLocatorString } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import routeConfiguration from '../../routeConfiguration';

import css from './ListingPage.module.css';

const MAX_VISIBLE_REVIEWS = 1;

const redirectToLoginPage = (location, history) => {
  const state = {
    from: `${location.pathname}${location.search}${location.hash}`,
  };

  const routes = routeConfiguration();
  history.push(createResourceLocatorString('LoginPage', routes), state);
};

const ReviewsHeading = ({ listingId, listingTitle, reviews, addReviewButton }) => (
  <div className={css.reviewsHeadingContainer}>
    {reviews.length > 1 ? (
      <NamedLink
        className={css.reviewsHeading}
        name="ReviewsPage"
        params={{ id: listingId.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="ListingPage.reviewsHeading" values={{ count: reviews.length }} />
        <IconArrowHead className={css.reviewsHeadingIcon} direction="right" />
      </NamedLink>
    ) : (
      <h2 className={css.reviewsHeading}>
        <FormattedMessage id="ListingPage.reviewsHeading" values={{ count: reviews.length }} />
      </h2>
    )}
    {addReviewButton}
  </div>
);

const FeaturedReviewSection = props => {
  const {
    intl,
    listingId,
    listingTitle,
    reviews,
    featuredReview,
    currentUserReactions,
    handleReviewLikeOrDislike,
    fetchReviewsError,
    addReviewLikeInProgress,
    addReviewDislikeInProgress,
    addReviewLikeError,
    addReviewDislikeError,
    addReviewButton,
    reviewModal,
  } = props;

  return (
    <div className={css.sectionReviews} id="reviews">
      <ReviewsHeading
        listingId={listingId}
        listingTitle={listingTitle}
        reviews={reviews}
        addReviewButton={addReviewButton}
      />
      {fetchReviewsError ? (
        <h2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </h2>
      ) : null}
      <Review
        intl={intl}
        review={featuredReview}
        currentUserReactions={currentUserReactions}
        handleReviewLikeOrDislike={handleReviewLikeOrDislike}
        likeOrDislikeReviewInProgress={addReviewLikeInProgress || addReviewDislikeInProgress}
        likeOrDislikeReviewError={addReviewLikeError || addReviewDislikeError}
        isFeatured
      />
      {reviewModal}
    </div>
  );
};

const RegularReviewSection = props => {
  const {
    listingId,
    listingTitle,
    reviews,
    maxVisibleReviews,
    currentUserReactions,
    handleReviewLikeOrDislike,
    fetchReviewsError,
    addReviewLikeInProgress,
    addReviewDislikeInProgress,
    addReviewLikeError,
    addReviewDislikeError,
    addReviewButton,
    reviewModal,
  } = props;

  return (
    <div className={css.sectionReviews}>
      <ReviewsHeading
        listingId={listingId}
        listingTitle={listingTitle}
        reviews={reviews}
        addReviewButton={addReviewButton}
      />
      {fetchReviewsError ? (
        <h2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </h2>
      ) : null}
      <Reviews
        reviews={reviews.slice(0, maxVisibleReviews)}
        currentUserReactions={currentUserReactions}
        handleReviewLikeOrDislike={handleReviewLikeOrDislike}
        likeOrDislikeReviewInProgress={addReviewLikeInProgress || addReviewDislikeInProgress}
        likeOrDislikeReviewError={addReviewLikeError || addReviewDislikeError}
      />
      {reviewModal}
    </div>
  );
};

const SectionReviews = props => {
  const {
    intl,
    location,
    history,
    currentUser,
    currentUserReactions,
    listingId,
    listingTitle,
    reviews,
    featuredReviewId,
    fetchReviewsError,
    isOwnListing,
    sendReviewInProgress,
    sendReviewError,
    addReviewLikeInProgress,
    addReviewLikeError,
    addReviewDislikeInProgress,
    addReviewDislikeError,
    onSendReview,
    onAddReviewLike,
    onAddReviewDislike,
    onManageDisableScrolling,
  } = props;

  const [isReviewModalOpen, setReviewModalOpen] = useState(false);
  const [reviewSubmitted, setReviewSubmitted] = useState(false);

  const handleReviewLikeOrDislike = (type, reviewId) => {
    if (currentUser) {
      if (type === THUMB_TYPE_LIKE) {
        onAddReviewLike(reviewId);
      } else if (type === THUMB_TYPE_DISLIKE) {
        onAddReviewDislike(reviewId);
      } else {
        console.error(new Error('Unknown like or dislike type'), {
          likeOrDislikeType: type,
        });
      }
    } else {
      redirectToLoginPage(location, history);
    }
  };

  const handleOpenReviewModal = () => {
    if (currentUser) {
      setReviewModalOpen(true);
    } else {
      redirectToLoginPage(location, history);
    }
  };

  const handleReviewSubmit = (values, form) => {
    onSendReview(values).then(() => {
      setReviewModalOpen(false);
      setReviewSubmitted(true);
      form.reset();
      notification.success(
        'Review submitted successfully. It will appear on the platform shortly.'
      );
    });
  };

  const addReviewButton = isOwnListing
    ? null
    : !reviewSubmitted && (
        <InlineTextButton
          type="button"
          className={css.reviewButton}
          onClick={handleOpenReviewModal}
        >
          {reviews.length > 0 ? (
            <FormattedMessage id="ListingPage.reviewButton" />
          ) : (
            <FormattedMessage id="ListingPage.noReviewsButton" />
          )}
        </InlineTextButton>
      );

  const reviewModal = (
    <ShowReviewModal
      id="ReviewOrderModal"
      isOpen={isReviewModalOpen}
      onCloseModal={() => setReviewModalOpen(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      onSubmitReview={handleReviewSubmit}
      revieweeName={listingTitle}
      reviewSent={reviewSubmitted}
      sendReviewInProgress={sendReviewInProgress}
      sendReviewError={sendReviewError}
    />
  );

  const featuredReview = reviews.find(
    review => review.id === featuredReviewId && review.attributes.likes > 0
  );
  const showFeaturedReview = reviews.length > 1 && featuredReview;

  if (showFeaturedReview) {
    return (
      <FeaturedReviewSection
        intl={intl}
        listingId={listingId}
        listingTitle={listingTitle}
        reviews={reviews}
        featuredReview={featuredReview}
        currentUserReactions={currentUserReactions}
        handleReviewLikeOrDislike={handleReviewLikeOrDislike}
        fetchReviewsError={fetchReviewsError}
        addReviewLikeInProgress={addReviewLikeInProgress}
        addReviewDislikeInProgress={addReviewDislikeInProgress}
        addReviewLikeError={addReviewLikeError}
        addReviewDislikeError={addReviewDislikeError}
        addReviewButton={addReviewButton}
        reviewModal={reviewModal}
      />
    );
  }

  return (
    <RegularReviewSection
      listingId={listingId}
      listingTitle={listingTitle}
      reviews={reviews}
      maxVisibleReviews={MAX_VISIBLE_REVIEWS}
      currentUserReactions={currentUserReactions}
      handleReviewLikeOrDislike={handleReviewLikeOrDislike}
      fetchReviewsError={fetchReviewsError}
      addReviewLikeInProgress={addReviewLikeInProgress}
      addReviewDislikeInProgress={addReviewDislikeInProgress}
      addReviewLikeError={addReviewLikeError}
      addReviewDislikeError={addReviewDislikeError}
      addReviewButton={addReviewButton}
      reviewModal={reviewModal}
    />
  );
};

export default SectionReviews;
